:root {
  --size: min(1300px, calc(((100vw + 100vh) / 2)));
  --width: 0px;
  --height: 0px;
  --board-size: min(max(calc(var(--size) * 0.4), 500px), 100vw);
  --amount-card-width: 8;
  --amount-card-height: 8;
  --card-size: calc(var(--board-size) / var(--amount-card-width));
  --hero-width: calc(var(--size) * 0.17);
  --selected-hero-cont-width: calc(var(--width) * 0.25);
  --power-avatar-size: calc(var(--size) * 0.03);
  --user-avatar-size: calc(var(--size) * 0.05);
}

body {
  font-family: verdana;
}

.user-avatar-flex {
  display: flex;
  justify-content: center;
  width: 100%;
}

.user-avatar {
  width: var(--user-avatar-size);
  height: var(--user-avatar-size);
  border-radius: calc(var(--user-avatar-size) * 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: grid;
  align-items: center;
  justify-content: center;
}

.user-avatar-score {
  width: calc(var(--user-avatar-size) * 0.4);
  height: calc(var(--user-avatar-size) * 0.4);
  line-height: calc(var(--user-avatar-size) * 0.4);
  border-radius: calc(var(--user-avatar-size) * 0.4 * 0.5);
  background: white;
  font-weight: bold;
  text-align: center;
}

.you-avatar {
  background-image: url(./hero-alone.jpg);
  color: rgb(26, 112, 26);
}

.scum-avatar {
  background-image: url(./scum-alone.jpg);
  color: rgb(112, 26, 26);
}

.selected-hero-cont {
  padding: calc(var(--size) * 0.01);
  text-align: center;
  display: flex;
  justify-content: center;
  width: var(--selected-hero-cont-width);
}

.selected-hero-cont-cont {
  width: var(--hero-width);
}

.board-flex {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.board {
  width: var(--board-size);
}

.board-line {
  display: flex;
  /* height: 50px; */
  height: var(--card-size);
  justify-content: space-around;
}

.card-flex-col {
  /* width: 50px; */
  width: var(--card-size);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card-flex-row {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
}

.card-paper {
  width: 85%;
  border-radius: 5%;
  /* outline: 1px solid black; */
  position: relative;
  transition: transform 0.1s;
  font-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.card-empty {
  background-image: url("./empty.jpg");
}

.card-player-1 {
  /* background: #71baffbb; */
  background-image: url("./hero-alone.jpg");
}

/* .card-player-2 {
  background: blue;
} */

.card-top-pick {
  transform: rotate(20deg) scale(1.3);
  z-index: 2;
}

.card-just-took {
  outline: 3px solid rgb(255, 0, 221);
  transform: rotate(20deg) scale(1.4);
  z-index: 2;
}

.card-op-took {
  background-image: url("./scum-alone.jpg");
}

.card-empty-lost {
  background-image: url("./empty-brow.jpg");
}



.streak-verti {
  position: absolute;
  width: 40%;
  height: 100%;
  background-image: url(./golden-verti.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.streak-hori {
  position: absolute;
  width: 100%;
  height: 40%;
  background-image: url(./golden-hori.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.value {
  --value-size: calc(var(--card-size) * 0.27);
  width: var(--value-size);
  height: var(--value-size);
  line-height: calc(var(--card-size) * 0.27);
  border-radius: calc(var(--card-size) * 0.27 * 0.5);
  margin-top: calc(var(--card-size) * 0.27 * -0.2);
  margin-left: calc(var(--card-size) - var(--value-size) * 1.4);
  outline: 1.5px solid black;
  background: white;
  position: absolute;
  text-align: center;
  font-size: calc(var(--card-size) * 0.17);
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
}

.value-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 158, 158);
}

.value-bar-top {
  width: 100%;
  /* background: rgb(161, 255, 177); */
  background: white;
}

.value-text {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
}

.card-clickable {
  transform: scale(1.1);
  transform-origin: center;
}

.button-take-pick {
  background: #22ff55bb;
}

.button-take-random {
  background: #dadadabb;
}

.op-discarded-flex-col {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
}

.op-discarded-flex-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}

.op-discarded {
  width: 50%;
  height: 100%;
  background-image: url(./forbid.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 5px;
  margin-top: calc(var(--card-size) * 0.17);
}

.buttons {
  /* margin-top: 20px;
  margin-bottom: 20px; */
  display: flex;
  width: 100vw;
  justify-content: center;
}

.card-revealed {
  background: green;
  outline: 3px solid red;
}

.button {
  width: 300px;
  height: 150px;
  line-height: 150px;
  font-size: 30px;
  text-align: center;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}

.bottom {
  height: 200px;
}

.knock {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: rgb(148, 255, 143);
  cursor: pointer;
  border-radius: 10px;
}

.ready {
  background: #dadada;
}

.score {
  width: var(--board-size);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  outline: 1px solid black;
  margin-top: 10px;
  font-weight: bold;
  font-size: calc(var(--board-size) * 0.025)
}

.score-text {
  position: absolute;
}

.score-text-op {
  position: absolute;
  text-align: right;
  width: 100%;
}

.score-item {
  width: 200px;
  transition: width 1s;
  white-space: nowrap;
  position: relative;
}

.score-item-op {
  text-align: right;
}

.infos {
  margin-top: 5px;
  outline: 1px solid black;
  text-align: center;
  font-size: calc(var(--size) * 0.02);
  height: 30px;
  line-height: 30px;
}

.hero-cont {
  margin-top: 30px;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: calc(var(--size) * 0.015)
}

.hero {
  text-align: center;
  outline: 1px solid black;
  width: var(--hero-width);
  margin-top: 10px;
  transform-origin: center;
  transition: transform 0.2s;
  cursor: pointer;
  background: white;
  margin: 5px;
}

.hero-gold-flex {
  display: flex;
  height: calc(var(--hero-width) * 0.1);
  line-height: calc(var(--hero-width) * 0.1);
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.hero-gold-image {
  height: calc(var(--hero-width) * 0.1);
  width: calc(var(--hero-width) * 0.1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.hero-gold-value {
  margin-left: calc(var(--hero-width) * 0.03);
}

.hero:hover {
  transform: scale(1.07);
  z-index: 1;
}

.hero-header {
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}

.hero-image-grid {
  width: var(--hero-width);
  height: var(--hero-width);
  display: grid;
  align-content: center;
  justify-content: center;
}

.hero-image {
  width: calc(var(--hero-width) * 0.9);
  height: calc(var(--hero-width) * 0.9);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: 1px solid grey;
}

.hero-text {
  padding: 7%;
  padding-top: 0px;
}

.hero-power-flex {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.hero-power {
  width: var(--board-size);
  margin-top: calc(var(--size) * 0.01);
  margin-bottom: calc(var(--size) * 0.007);
  display: flex;
  background: rgb(233, 233, 233);
}

.hero-power-avatar {

  width: var(--power-avatar-size);
  height: var(--power-avatar-size);
  border-radius: calc(var(--power-avatar-size) * 0.5);
  margin-left: calc(var(--power-avatar-size) * -0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.hero-power-content {
  margin-left: 10px;
  line-height: var(--power-avatar-size);
  font-size: calc(var(--size) * 0.012);
  font-weight: bold;
}